import React from "react";
import { FaGithub, FaLinkedin, FaStackOverflow } from "react-icons/fa";
import styles from "./Social.module.css";
import { Link } from "react-router-dom";

export default function Social() {
  return (
    <div className={styles.socialContainer}>
      <Link to="https://www.linkedin.com/in/dannyschhun/">
        <FaLinkedin className={styles.icon} />
      </Link>
      <Link to="https://github.com/dannyschhun">
        <FaGithub className={styles.icon} />
      </Link>
      <Link to="https://stackoverflow.com/users/9238194/danny-chhun?tab=profile">
        <FaStackOverflow className={styles.icon} />
      </Link>
    </div>
  );
}
