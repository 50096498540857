import React from "react";
import styles from "./Name.module.css";
import { TbPointFilled } from "react-icons/tb";

export default function Name() {
  return (
    <>
      <div className={styles.nameContainer}>
        <p className={styles.firstName}>D A N N Y</p>
        <TbPointFilled className={styles.dot} />
        <p className={styles.lastName}> C H H U N</p>
      </div>
      <div className={styles.titleContainer}>
        <p className={styles.title}>Full-Stack Developer</p>
      </div>
    </>
  );
}
