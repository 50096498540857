import React from "react";
import styles from "./AmoutMe.module.css";
// import { VscDebugBreakpointLog } from "react-icons/vsc";
// import BoldText from "../BoldText";

export default function AboutMe() {
  return (
    <div className={styles.textContainer}>
      {/* <div className={styles.textLine}>
        <VscDebugBreakpointLog className={styles.bullet} />
        <p className={styles.bulletText}>5+ years of experience</p>
      </div>
      <div className={styles.textLine}>
        <VscDebugBreakpointLog className={styles.bullet} />
        <p className={styles.bulletText}>Proficient Backend: </p>
      </div>
      <p className={styles.tabbedText}>[Java, NodeJS, SQL, GraphQL]</p>
      <div className={styles.textLine}>
        <VscDebugBreakpointLog className={styles.bullet} />
        <p className={styles.bulletText}>Proficient Frontend: </p>
      </div>
      <p className={styles.tabbedText}>[JavaScript, React/React Native]</p> */}
      <div className={styles.textContainer}>
        {/* <BoldText
          text="As a seasoned software engineer with over
          5 years of experience, I excel in backend
          technologies such as Java, NodeJS, SQL, and GraphQL, alongside
          expertise in frontend tools including JavaScript, React, and React
          Native. My passion lies in crafting robust solutions that seamlessly
          integrate across platforms."
          boldText="5 years of experience Java NodeJS SQL GraphQL JavaScript React Native"
        /> */}
        <div>
          <p className={styles.normalText}>
            As a seasoned software engineer with over
            <strong className={styles.boldText}> 5 years of experience </strong>
            I excel in backend technologies such as
            <strong className={styles.boldText}>
              {" "}
              NodeJS, SQL, Java and GraphQL,{" "}
            </strong>
            alongside expertise in frontend tools including
            <strong className={styles.boldText}>
              {" "}
              JavaScript, React, and React Native.{" "}
            </strong>
            My passion lies in crafting robust solutions that seamlessly
            integrate across platforms.
          </p>
        </div>
      </div>
    </div>
  );
}
