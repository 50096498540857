import React from "react";
import styles from "./ScrollDownArrow.module.css"; // Import CSS for styling

const ScrollDownArrow = () => {
  const handleScroll = () => {
    window.scrollBy({
      top: window.innerHeight - 100, // Adjust scroll distance as needed
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.scrollDown} onClick={handleScroll}>
      <span className={styles.arrow}></span>
    </div>
  );
};

export default ScrollDownArrow;
