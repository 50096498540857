import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer";
import ProjectList from "./components/ProjectList";
import styles from "./Projects.module.css";
import ScrollDownArrow from "../../components/ScrollDownArrow";

export default function Projects() {
  const [hideElement, setHideElement] = useState(false);

  useEffect(() => {
    const container = document.getElementById("scroll-container");

    const handleScroll = () => {
      // Check if container has scrolled down
      if (
        container.scrollHeight -
          container.scrollTop -
          container.clientHeight ===
        0
      ) {
        setHideElement(true);
      } else {
        setHideElement(false);
      }
    };

    // Add scroll event listener to container
    container.addEventListener("scroll", handleScroll);

    // Clean up: remove event listener on component unmount
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ProjectItem = (project) => {
    return (
      <ProjectList
        key={project.projectName}
        projectName={project.projectName}
        techStack={project.techStack}
        appDescription={project.appDescription}
      />
    );
  };

  return (
    <PageContainer>
      {!hideElement && <ScrollDownArrow />}
      <div className={styles.bodyContainer} id="scroll-container">
        <div className={styles.projectContainer}>
          {PROJECTS.map((project) => ProjectItem(project))}
        </div>
      </div>
    </PageContainer>
  );
}

const PROJECTS = [
  {
    projectName: "VoltHub App",
    techStack: "[Expo, React Native, JavaScript, nHost, GraphQl, PostgresSQL]",
    appDescription: `Created a web application for consumers and contractors to properly quote users 
on installations, such as Heat Pump Water Heaters, by totaling all the incentives and rebates they 
can use, and finalizing the total end result.`,
  },
  {
    projectName: "VoltHub Marketing Site",
    techStack: "[Expo, React Native, TypeScript]",
    appDescription: `Created a marketing site to provide a clear understanding of our core mission: to 
inform users about government-approved incentives and rebates that can reduce the total cost of 
installing environmentally friendly products.`,
  },
  {
    projectName: "Personal Website",
    techStack: "[React, JavaScript, Netlify, Hasura, GraphQL]",
    appDescription: `Personal portfolio website to showcase a small portion of my programming skills and 
list projects and professional social networks. This site serves as a personal business card.`,
  },
  {
    projectName: "Discord Bot",
    techStack: "[JavaScript, Node.js, Hasura, AWS]",
    appDescription: `Created a discord bot with multiple functions planned. One set up right 
now is a word of the day feature to give a new words to the server everyday as well as a 
function to define any word given to it. Next planned feature is an interactive One night 
werewolf game.`,
  },
  {
    projectName: "SEQL iOS App",
    techStack: "[Expo, React Native, JavaScript, Hasura, GraphQL, SQL]",
    appDescription: `Developed an iOS app for young aspiring athletes to gain instant access to 
learning programs from professional athletes and connect with a local videographer to get 
free filmed highlights of themselves at their matches.`,
  },
  {
    projectName: "SEQL Videographer Portal",
    techStack: "[Expo, React Native, JavaScript, Hasura, GraphQL, SQL",
    appDescription: `Spearheaded the development of a portal website for our approved videographers 
to upload athletes' highlights that they've filmed. This allows us to instantly organize and 
make them available in our athlete iOS app.`,
  },
  {
    projectName: "Scoring API",
    techStack: "[Java, Oracle RDBMS, AWS]",
    appDescription: `Developed a business API to gather financial information from a user questionnaire 
in order to make recommendations for financial plans, packages, and services specifically tailored to their situation.`,
  },
  {
    projectName: "Health and Wellness Adapter",
    techStack: "[Java, Oracle RDBMS, Kafka]",
    appDescription: `Created a business API to process customers' Health and Wellness accounts, making it available to 
them within an hour, as opposed to the previous manual processing time of 3 days.`,
  },
];
