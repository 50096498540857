import React from "react";
import PageContainer from "../../components/PageContainer";
import AboutMe from "./components/AboutMe";

export default function Homepage() {
  return (
    <PageContainer>
      <AboutMe />
    </PageContainer>
  );
}
