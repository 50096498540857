import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage/Homepage";
import Projects from "./pages/projects/Projects";
// import Projects from "./components/pages/Portfolio/Projects";

function App() {
  useEffect(() => {
    document.title = "Danny Chhun";
  }, []);

  return (
    <div className="App">
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projects" element={<Projects />} />
          {/* <Route path="/projects" element={<Projects />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
