import React from "react";
import styles from "./ProjectList.module.css";

export default function ProjectList({
  projectName,
  techStack,
  appDescription,
}) {
  return (
    <div className={styles.container}>
      {/* Project Name  */}
      <div className={styles.dotWithLine}>
        <div className={styles.dot}></div>
        <div className={styles.line}></div>
        <p className={styles.projectName}>{projectName}</p>
        <div className={styles.lineAfter}></div>
      </div>
      {/* Project Name End */}

      {/* Project Tech Stack */}
      <p className={styles.techStackText} style={{ marginLeft: "20px" }}>
        Tech Stack:{" "}
        <strong className={styles.techStackText}>{techStack}</strong>
      </p>

      {/* Project Body */}

      <p
        className={styles.bodyText}
        style={{ marginLeft: "20px", marginTop: "5px" }}
      >
        {appDescription}
      </p>
    </div>
  );
}
