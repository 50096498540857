import React from "react";
import styles from "./Nav.module.css";
import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <div className={styles.navContainer}>
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? styles.activeNavText : styles.navText
        }
        to="/"
      >
        HOME
      </NavLink>
      <NavLink
        className={({ isActive, isPending }) =>
          isActive ? styles.activeNavText : styles.navText
        }
        to="/projects"
      >
        PROJECTS
      </NavLink>
    </div>
  );
}
